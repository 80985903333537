import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useRouter } from 'next/router';
import Search from '@fuww/library/src/Jobs/Search';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import { useSiteContext } from '../../SiteContext';
import filterQuery from '../../../lib/filterQuery';
import withQuery from '../../../lib/withQuery';
import replaceRoute from '../../../lib/replaceRoute';
import getJobsRoute from '../../../lib/getJobsRoute';
import cityFiltersQuery
  from '../../../lib/queries/jobs/cityFiltersQuery.graphql';

const jobSearchFiltersProperties = {
  keywords: PropTypes.string,
  searchFieldTitle: PropTypes.string.isRequired,
  onSearchTrailingIconSelect: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  locationSearch: PropTypes.string,
  locationSearchFieldTitle: PropTypes.string.isRequired,
  onLocationSearchTrailingIconSelect: PropTypes.func.isRequired,
  onLocationSearchChange: PropTypes.func.isRequired,
  categoryParam: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  categoryFieldTitle: PropTypes.string.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  showLoader: PropTypes.bool.isRequired,
  handleLocaleFilterChange: PropTypes.func,
  showJobsForAllLocales: PropTypes.bool,
  checkboxLabel: PropTypes.string,
};

const jobSearchFiltersWithCitiesProperties = {
  ...jobSearchFiltersProperties,
  data: PropTypes.shape({
    cities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }).isRequired,
};

type JobSearchFiltersWithCitiesProperties = InferProps<
typeof jobSearchFiltersWithCitiesProperties
> & {
  showJobsForAllLocales?: boolean;
};

const JobSearchFiltersWithCities = withQuery(({
  data,
  searchFieldTitle,
  locationSearchFieldTitle,
  categoryFieldTitle,
  keywords,
  onSearchChange,
  onSearchTrailingIconSelect,
  locationSearch,
  onLocationSearchChange,
  onLocationSearchTrailingIconSelect,
  categoryParam,
  onCategoryChange,
  categoryOptions,
  showLoader,
  handleLocaleFilterChange,
  showJobsForAllLocales,
  checkboxLabel,
}: JobSearchFiltersWithCitiesProperties) => {
  const { locale, isCareerPage } = useSiteContext();
  const { query } = filterQuery(useRouter());
  const { citySlug } = query;

  const { cities } = { ...data };
  return (
    <Search
      cities={
        cities?.map(
          ({ id, name, slug }) => {
            const selected = slug === citySlug;
            return {
              id,
              name,
              onClick: () => {
                const updatedQuery = {
                  ...query, citySlug: selected ? null : slug,
                };
                replaceRoute(
                  getJobsRoute(updatedQuery, locale, isCareerPage),
                  updatedQuery,
                );
              },
              selected,
            };
          },
        )
      }
      searchFieldTitle={searchFieldTitle}
      locationSearchFieldTitle={locationSearchFieldTitle}
      categoryFieldTitle={categoryFieldTitle}
      keywords={keywords}
      onSearchChange={onSearchChange}
      onSearchTrailingIconSelect={onSearchTrailingIconSelect}
      locationSearch={locationSearch}
      onLocationSearchChange={onLocationSearchChange}
      onLocationSearchTrailingIconSelect={onLocationSearchTrailingIconSelect}
      categoryParam={categoryParam}
      onCategoryChange={onCategoryChange}
      categoryOptions={categoryOptions}
      showLoader={showLoader}
      handleLocaleFilterChange={handleLocaleFilterChange}
      showJobsForAllLocales={showJobsForAllLocales}
      checkboxLabel={checkboxLabel}
    />
  );
}, { renderAlways: true });

type JobSearchFiltersProperties = InferProps<
typeof jobSearchFiltersProperties
> & {
  showJobsForAllLocales?: boolean;
};

const JobSearchFilters = (properties: JobSearchFiltersProperties) => {
  const { locale } = useSiteContext();

  return (
    <JobSearchFiltersWithCities
      query={cityFiltersQuery}
      variables={{
        locales: [locale],
        limit: 10,
        sort: 'ACTIVE_JOBS_COUNT_DESCENDING',
      }}
      errorMessage="Problem loading city filters"
      {...properties}
      loader={<HiddenLoader />}
    />
  );
};

export default JobSearchFilters;
